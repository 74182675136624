// @mui
// hooks
import {Button, Checkbox, FormControlLabel, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import Tour from "reactour";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import Iconify from "../../../components/Iconify";
import {IconButtonAnimate} from "../../../components/animate";
import useSettings from "../../../hooks/useSettings";
// components

// ----------------------------------------------------------------------

StartTourButton.propTypes = {
    onDoNotShowAgainCheck: PropTypes.func,
    onJumpClick: PropTypes.func,
    goTo: PropTypes.func,
};

function StartTourButton({onDoNotShowAgainCheck, onJumpClick, goTo}) {
    return (
        <Stack width={1} direction="column" justifyContent="flex-end" spacing={1} sx={{mt: 2}}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        color="primary"
                        onChange={(event) => onDoNotShowAgainCheck(event.target.checked)}
                        icon={<Iconify icon={'eva:radio-button-off-outline'}/>}
                        checkedIcon={<Iconify icon={'eva:checkmark-circle-2-outline'}/>}
                    />
                }
                label="Não mostrar novamente"
                sx={{minWidth: 72, mr: 0}}
            />
            <Stack width={1} direction="row" justifyContent="flex-end" spacing={1} sx={{mt: 2}}>
                <Button variant={"outlined"} onClick={() => onJumpClick(false)}>
                    Pular
                </Button>
                <Button variant={"contained"} onClick={() => goTo(1)}>
                    Começar
                </Button>
            </Stack>
        </Stack>
    );
}

export default function HelpIcon() {
    const {pathname} = useLocation();
    const [, , secondPathSegment, thirdPathSegment] = pathname.split('/');

    const {showTour, onChangeTour} = useSettings();
    const [tourOpen, setTourOpen] = useState(false);
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        setTourOpen(showTour);
    }, []);


    const tourHomeConfig = [
        {
            selector: '',
            content: ({goTo}) => (
                <div>
                    Olá, sou sua assistente Sofia.<br/><br/>
                    Vou te ajudar a navegar pelo sistema e mostrar as principais funcionalidades da
                    ferramenta. <br/><br/>
                    Está pronto?
                    <StartTourButton
                        onDoNotShowAgainCheck={setDoNotShowAgain}
                        onJumpClick={setTourOpen}
                        goTo={goTo}
                    />
                </div>
            )
        },
        {
            selector: '[data-tut="dashboard-view"]',
            content: `Esta é a visão geral do dashboard, por aqui você conseguirá acompanhar todas as métricas e perfomances do seus leads.`
        },
        {
            selector: '[data-tut="credits"]',
            content: `Estes são seus créditos disponíveis para serem usados para captação de novos leads.`
        },
        {
            selector: '[data-tut="account-popover"]',
            content: `Para informações de conta e preferências, acesse seu perfil.`,
        },
        {
            selector: '[data-tut="campaigns"]',
            content: `Realize uma busca avançada, crie campanhas e comece a prospectar novos leads.`
        },
        {
            selector: '[data-tut="leads"]',
            content: `Navegue pelos seus leads e os organize pelas diferentes etapas do funil.`,
            position: "left"
        },
        {
            selector: '[data-tut="contacts"]',
            content: "Encontre detalhes de contato dos seus leads."
        },
        {
            selector: '[data-tut="teams"]',
            content: "Crie times, convide sua equipe e atribua leads para cada responsável."
        },
        {
            selector: '[data-tut="settings"]',
            content: "Configure sua conta ou altere as diferentes etapas do funil de vendas.",
            action: () => console.log(``)
        },
        {
            selector: '[data-tut="help"]',
            content: ({goTo}) => (
                <div>
                    Viu como é fácil navegar pelo sistema?!<br/><br/>
                    Caso houver dúvida em qualquer parte do sistema, é só clicar neste botão para obter ajuda.<br/><br/>
                    <Stack width={1} direction="column" justifyContent="flex-end" spacing={1} sx={{mt: 2}}>
                        <Stack width={1} direction="row" justifyContent="flex-end" spacing={1} sx={{mt: 2}}>
                            <Button variant={"outlined"} onClick={() => goTo(0)}>
                                Recomeçar
                            </Button>
                            <Button variant={"contained"} onClick={() => setTourOpen(false)}>
                                Fechar
                            </Button>
                        </Stack>
                    </Stack>
                </div>
            ),
            stepInteraction: false,
        },
    ];
    const tourLeadsConfig = [];
    const tourContactsConfig = [];
    const tourCampaignsConfig = [
        {
            selector: '[data-tut="campaigns-view"]',
            content: ({goTo}) => (
                <div>
                    Visualize e gerencie todas suas campanhas a partir desta página.
                    <StartTourButton
                        onDoNotShowAgainCheck={setDoNotShowAgain}
                        onJumpClick={setTourOpen}
                        goTo={goTo}
                    />
                </div>
            ),
            position: 'top'
        },
        {
            selector: '[data-tut="search-bar"]',
            content: `Pesquisa campanhas específicas por seu título.`
        },
        {
            selector: '[data-tut="table-row-0"]',
            content: `Visualize detalhes de cada campanha, visualize quem é o responsável e acompanhe o progresso dos leads.`
        },
        {
            selector: '[data-tut="edit-table-row-0"]',
            content: `Edite detalhes, distribuição dos leads ou exclua uma campanha facilmente através deste botão.`
        },
        {
            selector: '[data-tut="create-button"]',
            content: `Crie uma nova campanha com filtros avançados para encontrar novos leads e distribua-os para para seu time de acordo com a sua necessidade.`
        },
    ];
    const tourCreateCampaignConfig = [
        {
            selector: '',
            content: ({goTo}) => (
                <div>
                    Nesta página você poderá criar uma campanha com filtros avançados para encontrar clientes e
                    fornecedores para o seu negócio. Segmente de acordo com a sua necessidade e utilize os filtros
                    necessários.
                    <StartTourButton
                        onDoNotShowAgainCheck={setDoNotShowAgain}
                        onJumpClick={setTourOpen}
                        goTo={goTo}
                    />
                </div>
            ),
        },
        {
            selector: '[data-tut="company-features"]',
            content: `Filtre por características de empresas que está buscando com um ou várias variáveis.`,
        },
        {
            selector: '[data-tut="location"]',
            content: `Filtre empresas por localização geral como cidade/estado ou especificamente por CEPs.`,
        },
        {
            selector: '[data-tut="opening-date"]',
            content: `Selecione empresas abertas em um determinado período que melhor se enquadre na sua necessidade.`,
        },
        {
            selector: '[data-tut="contact-options"]',
            content: `Restrinja sua busca por dados que realmente são essenciais para o seu negócio.`,
        },
    ];

    const tourConfigs = {
        app: tourHomeConfig,
        leads: tourLeadsConfig,
        contacts: tourContactsConfig,
        campaignslist: tourCampaignsConfig,
        campaignsnew: tourCreateCampaignConfig,
    };
    const selectedTourConfig = tourConfigs[`${secondPathSegment}${thirdPathSegment || ""}`];
    const [showTourCloseButton, setShowTourCloseButton] = useState(selectedTourConfig !== tourConfigs.app);
    const [showTourNavButtons, setShowTourNavButtons] = useState(selectedTourConfig !== tourConfigs.app);

    const openTour = () => {
        setTourOpen(true);
    };

    if (!selectedTourConfig) return (<></>);

    return (
        <>
            <IconButtonAnimate
                data-tut="help"
                onClick={openTour}
                sx={{
                    width: 40,
                    height: 40,
                    ...(tourOpen && {bgcolor: 'action.selected'}),
                }}
            >
                <Iconify icon={'eva:question-mark-circle-outline'}/>
            </IconButtonAnimate>
            <Tour
                onRequestClose={() => {
                    setTourOpen(false);
                }}
                steps={selectedTourConfig}
                isOpen={tourOpen}
                onBeforeClose={() => {
                    setCurrentStep(0);
                    onChangeTour(doNotShowAgain)
                }}
                goToStep={currentStep}
                showCloseButton={showTourCloseButton}
                closeWithMask={false}
                showNavigation={showTourNavButtons}
                showButtons={showTourNavButtons}
                getCurrentStep={(curr) => {
                    setCurrentStep(curr);
                    const maxLength = selectedTourConfig.length;
                    if (curr === 0) {
                        setShowTourNavButtons(false)
                        setShowTourCloseButton(false)
                    } else {
                        setShowTourNavButtons(true)
                        setShowTourCloseButton(true)
                    }
                    if (selectedTourConfig === tourConfigs.app) {
                        if (curr === maxLength - 1) {
                            setShowTourNavButtons(false)
                            setShowTourCloseButton(false)
                        }
                    }
                }}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={"#5cb7b7"}
                onAfterOpen={() => {
                    if (currentStep === 0) {
                        setShowTourNavButtons(false)
                        setShowTourCloseButton(false)
                    }
                }}
            />
        </>
    );
}