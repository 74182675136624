import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: null,
    companiesCount: 0,
    locationOptions: [],
    company: null,
    segmentSummary: null,
    companies: [],
    neighborhoodOptions: [],
    isLocationLoading: false,
    isSegmentLoading: false,
    isDebtLoading: false,
    isNeighborhoodLoading: false,
    sortBy: null,
    filters: {
        activities: [],
        legalNatures: [],
        names: [],
        minCapital: null,
        maxCapital: null,
        status: [{
            "value": "2",
            "description": "Ativas"
        }],
        headquartersBranch: {
            headquarter: true,
            branch: true
        },
        companySize: {
            MEI: true,
            ME: true,
            EPP: true,
            None: true
        },
        location: [],
        neighborhood: [],
        contact: 1,
        zipCodes: [],
        areaCodes: [],
        dateRange: [1, 9]
    },
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null,
    },
};

const slice = createSlice({
    name: 'openSearch',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        startSegmentLoading(state) {
            state.isSegmentLoading = true;
        },

        // START LOADING
        startDebtLoading(state) {
            state.isDebtLoading = true;
        },

        // START LOADING
        startLocationLoading(state) {
            state.isLocationLoading = true;
        },

        // START LOADING
        startNeighborhoodLoading(state) {
            state.isNeighborhoodLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isNeighborhoodLoading = false;
            state.isLocationLoading = false;
            state.error = action.payload;
        },

        // RESET FILTERS
        resetFilters(state) {
            state.filters = {
                activities: [],
                legalNatures: [],
                names: [],
                minCapital: null,
                maxCapital: null,
                status: [{
                    "value": "2",
                    "description": "Ativas"
                }],
                headquartersBranch: {
                    headquarter: true,
                    branch: true
                },
                companySize: {
                    MEI: true,
                    ME: true,
                    EPP: true,
                    None: true
                },
                location: [],
                neighborhood: [],
                contact: 1,
                zipCodes: [],
                areaCodes: [],
                dateRange: [1, 9]
            };
        },

        // GET COMPANY
        getCompanySuccess(state, action) {
            state.isLoading = false;
            state.company = action.payload;
        },

        // GET SEGMENT SUMMARY
        getSegmentSummarySuccess(state, action) {
            state.isSegmentLoading = false;
            state.segmentSummary = action.payload;
        },

        // GET COMPANY DEBT
        getCompanyDebtSuccess(state, action) {
            state.isDebtLoading = false;
            state.companyDebt = action.payload;
        },

        // GET LOCATIONS
        getLocationsSuccess(state, action) {
            state.isLocationLoading = false;
            state.locationOptions = action.payload;
        },

        // GET NEIGHBORHOODS
        getNeighborhoodsSuccess(state, action) {
            state.isNeighborhoodLoading = false;
            state.neighborhoodOptions = action.payload;
        },

        // GET COMPANIES COUNT
        getCompaniesCountSuccess(state, action) {
            state.isLoading = false;
            state.companiesCount = action.payload;
            state.checkout.activeStep += 1;
        },

        // GET COMPANIES COUNT
        getCompaniesSuccess(state, action) {
            state.isLoading = false;
            state.companies = action.payload;
        },

        //  SET COMPANY DETAILS
        setCompanyDetails(state, action) {
            state.company = action.payload;
        },

        //  FILTER COMPANIES
        filterByActivities(state, action) {
            state.filters.activities = action.payload;
        },

        filterByLegalNature(state, action) {
            state.filters.legalNatures = action.payload;
        },

        filterByNames(state, action) {
            state.filters.names = action.payload;
        },

        filterByMinCapital(state, action) {
            state.filters.minCapital = action.payload;
        },

        filterByMaxCapital(state, action) {
            state.filters.maxCapital = action.payload;
        },

        filterByLocation(state, action) {
            state.filters.location = action.payload;
        },

        filterByNeighborhood(state, action) {
            state.filters.neighborhood = action.payload;
        },

        filterByStatus(state, action) {
            state.filters.status = action.payload;
        },

        filterByHeadquartersBranch(state, action) {
            state.filters.headquartersBranch = action.payload;
        },

        filterByCompanySize(state, action) {
            state.filters.companySize = action.payload;
        },

        filterByContact(state, action) {
            state.filters.contact = action.payload;
        },

        filterByZipCodes(state, action) {
            state.filters.zipCodes = action.payload;
        },

        filterByAreaCodes(state, action) {
            state.filters.areaCodes = action.payload;
        },

        filterByDateRange(state, action) {
            state.filters.dateRange = action.payload;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((cartItem) => cartItem.unit_price * cartItem.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const product = action.payload;
            state.checkout.cart = [product];
            state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
        },

        deleteCart(state, action) {
            state.checkout.cart = state.checkout.cart.filter((item) => item.id !== action.payload);
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            state.checkout.activeStep = action.payload;
        },

        increaseQuantity(state, action) {
            const productId = action.payload;
            state.checkout.cart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1,
                    };
                }
                return product;
            });
        },

        decreaseQuantity(state, action) {
            const productId = action.payload;
            state.checkout.cart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1,
                    };
                }
                return product;
            });
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    resetFilters,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    setCompanyDetails,
    filterByActivities,
    filterByLegalNature,
    filterByNames,
    filterByMinCapital,
    filterByMaxCapital,
    filterByStatus,
    filterByLocation,
    filterByNeighborhood,
    filterByHeadquartersBranch,
    filterByCompanySize,
    filterByContact,
    filterByZipCodes,
    filterByAreaCodes,
    filterByDateRange,
} = slice.actions;

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}

// ----------------------------------------------------------------------

export function searchCompanies(request) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/functions/v1/open_search', request, {
                timeout: 600000,
                baseURL: 'https://kpvwvteiyrejlsvkujbv.supabase.co'
            });
            dispatch(slice.actions.getCompaniesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function searchCompaniesCount(request) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            // const response = await axios.post('/api/search/count', request, {timeout: 120000, baseURL: 'https://b2moon-edge-api.vercel.app'});
            const response = await axios.post('/functions/v1/count_search', request, {
                timeout: 600000,
                baseURL: 'https://kpvwvteiyrejlsvkujbv.supabase.co'
            });
            dispatch(slice.actions.getCompaniesCountSuccess(response.data[0].count));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function searchCompany(cnpj) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/search/company/${cnpj}`);
            dispatch(slice.actions.getCompanySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSegmentSummary(cnpj) {
    return async () => {
        dispatch(slice.actions.startSegmentLoading());
        try {
            const response = await axios.get(`/api/empresa/ResumoSegmento/${cnpj}`, {
                baseURL: 'https://b2bleads.com.br'
            });
            dispatch(slice.actions.getSegmentSummarySuccess(response.data.resumo));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCompanyDebt(cnpj) {
    return async () => {
        dispatch(slice.actions.startDebtLoading());
        try {
            const response = await axios.get(`/api/empresa/dividas/${cnpj}`, {
                baseURL: 'https://b2bleads.com.br'
            });
            dispatch(slice.actions.getCompanyDebtSuccess(response.data.dividas));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLocations(location) {
    return async () => {
        dispatch(slice.actions.startLocationLoading());
        try {
            const response = await axios.get(`/api/search/${location}`);
            dispatch(slice.actions.getLocationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getNeighborhoods(locationId) {
    return async () => {
        dispatch(slice.actions.startNeighborhoodLoading());
        try {
            const response = await axios.get(`/api/search/neighborhood/${locationId}`);
            dispatch(slice.actions.getNeighborhoodsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}