import {format, formatDistanceToNow, formatRelative, getTime} from 'date-fns';
import {ptBR} from "date-fns/esm/locale";

// ----------------------------------------------------------------------

export function formatDate(date) {
    // Validate input
    if (!/^\d{8}$/.test(date)) {
        return "";
    }

    // Extract year, month, and day from the input string
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
}

export function fDate(date) {
    return format(new Date(date), 'dd MMMM yyyy', {
        locale: {
            ...ptBR
        }
    });
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm', {
        locale: {
            ...ptBR
        }
    });
}

export function fTime(date) {
    return format(new Date(date), 'HH:mm');
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: {
            ...ptBR
        }
    });
}

export function fRelative(date) {
    return formatRelative(new Date(date), new Date(), {
        locale: {
            ...ptBR
        }
    })
}