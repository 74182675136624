import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'básico',
    icon: <PlanFreeIcon />,
    price: 0,
    fullPrice: '',
    discountedPrice: '',
    planType: 'mês',
    planId: '',
    priceId: '',
    lists: [
      { text: '5 leads no mês', isAvailable: true },
      { text: '1 administrador', isAvailable: true },
      { text: '1 time e 1 participante extra', isAvailable: true },
      { text: '1 Campanha por mês', isAvailable: true },
      { text: 'Campanhas não expiram', isAvailable: true },
      { text: 'Importação de Leads', isAvailable: true },
      { text: 'Suporte por e-mail', isAvailable: true },
      { text: 'Busca de Leads com Filtros Avançados', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: false },
      { text: 'Personalização do Funil de Vendas', isAvailable: false },
      { text: 'Acompanhamento de resultados', isAvailable: false },
      { text: 'Geolocalização do Google', isAvailable: false },
    ],
    labelAction: 'iniciar grátis',
  },
  {
    subscription: 'standard',
    icon: <PlanStarterIcon />,
    price: `0,47`,
    fullPrice: 'R$97,00',
    discountedPrice: 'R$47,00',
    planType: 'mês',
    planId: 'prod_ORZpJWPBC47ZnA',
    priceId: 'price_1NeggGIg6gNaPiVCIbnGVPWt',
    lists: [
      { text: '100 leads no mês', isAvailable: true },
      { text: '1 administrador', isAvailable: true },
      { text: '5 participantes extra', isAvailable: true },
      { text: 'Sem limite de times', isAvailable: true },
      { text: 'Sem limite de campanhas por mês', isAvailable: true },
      { text: 'Acompanhamento de resultados ', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Geolocalização do Google (em breve)', isAvailable: true },
      { text: 'Cancele quando quiser', isAvailable: true },
    ],
    labelAction: 'Escolher Standard',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: `0,29`,
    fullPrice: 'R$197,00',
    discountedPrice: 'R$147,00',
    planType: 'mês',
    planId: 'prod_ORZqfltwMthJAi',
    priceId: 'price_1NeggkIg6gNaPiVCpvo0MyBh',
    lists: [
      { text: '500 leads no mês', isAvailable: true },
      { text: 'Sem limite de administradores', isAvailable: true },
      { text: 'Sem limite de participantes', isAvailable: true },
      { text: 'Sem limite de times', isAvailable: true },
      { text: 'Sem limite de campanhas por mês', isAvailable: true },
      { text: 'Acompanhamento de resultados ', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Geolocalização do Google (em breve)', isAvailable: true },
      { text: 'Suporte prioritário', isAvailable: true },
      { text: 'Cancele quando quiser', isAvailable: true },
    ],
    labelAction: 'Escolher Premium',
  },
];

export const _annualPricingPlans = [
  {
    subscription: 'básico',
    icon: <PlanFreeIcon />,
    price: 0,
    fullPrice: '',
    discountedPrice: '',
    planType: 'ano',
    planId: '',
    priceId: '',
    lists: [
      { text: '5 leads no mês', isAvailable: true },
      { text: '1 administrador', isAvailable: true },
      { text: '1 time e 1 participante extra', isAvailable: true },
      { text: '1 Campanha por mês', isAvailable: true },
      { text: 'Campanhas não expiram', isAvailable: true },
      { text: 'Importação de Leads', isAvailable: true },
      { text: 'Suporte por e-mail', isAvailable: true },
      { text: 'Busca de Leads com Filtros Avançados', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: false },
      { text: 'Personalização do Funil de Vendas', isAvailable: false },
      { text: 'Acompanhamento de resultados', isAvailable: false },
      { text: 'Geolocalização do Google', isAvailable: false },
    ],
    labelAction: 'iniciar grátis',
  },
  {
    subscription: 'standard',
    icon: <PlanStarterIcon />,
    price: `0,32`,
    fullPrice: 'R$564,00',
    discountedPrice: 'R$387,00',
    planType: 'ano',
    planId: 'prod_ORZpJWPBC47ZnA',
    priceId: 'price_1NeggGIg6gNaPiVCa9LLUdCv',
    lists: [
      { text: '1200 leads no mês', isAvailable: true },
      { text: '1 administrador', isAvailable: true },
      { text: '5 participantes extra', isAvailable: true },
      { text: 'Sem limite de times', isAvailable: true },
      { text: 'Sem limite de campanhas por mês', isAvailable: true },
      { text: 'Acompanhamento de resultados ', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Geolocalização do Google (em breve)', isAvailable: true },
      { text: 'Cancele quando quiser', isAvailable: true },
    ],
    labelAction: 'Escolher Standard',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: `0,20`,
    fullPrice: 'R$1764,00',
    discountedPrice: 'R$1227,00',
    planType: 'ano',
    planId: 'prod_ORZqfltwMthJAi',
    priceId: 'price_1NeggkIg6gNaPiVCUFkyWBvn',
    lists: [
      { text: '6000 leads no mês', isAvailable: true },
      { text: 'Sem limite de administradores', isAvailable: true },
      { text: 'Sem limite de participantes', isAvailable: true },
      { text: 'Sem limite de times', isAvailable: true },
      { text: 'Sem limite de campanhas por mês', isAvailable: true },
      { text: 'Acompanhamento de resultados ', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Geolocalização do Google (em breve)', isAvailable: true },
      { text: 'Suporte prioritário', isAvailable: true },
      { text: 'Cancele quando quiser', isAvailable: true },
    ],
    labelAction: 'Escolher Premium',
  },
];

export const _leadsPackages = [
  {
    subscription: '10 mil empresas',
    icon: <PlanStarterIcon />,
    price: `0,0067`,
    fullPrice: 'R$564,00',
    discountedPrice: 'R$67,00',
    planType: 'pacote',
    planId: 'prod_Pwbd4EygPCz2rE',
    priceId: 'price_1P6iQWIg6gNaPiVCg2lzIb1D',
    lists: [
      { text: '10 mil leads', isAvailable: true },
      { text: 'Arquivo Excel', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Leads extraídos por setores', isAvailable: true },
      { text: 'Sem mensalidade', isAvailable: true },
      { text: 'Use como quiser', isAvailable: true },
    ],
    labelAction: 'Comprar agora',
  },
  {
    subscription: '20 mil empresas',
    icon: <PlanStarterIcon />,
    price: `0,0054`,
    fullPrice: 'R$954,00',
    discountedPrice: 'R$109,90',
    planType: 'pacote',
    planId: 'prod_QYfFfGBwniZkxF',
    priceId: 'price_1PhXukIg6gNaPiVCKi6CxuCW',
    lists: [
      { text: '20 mil leads', isAvailable: true },
      { text: 'Arquivo Excel', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Leads extraídos por setores', isAvailable: true },
      { text: 'Sem mensalidade', isAvailable: true },
      { text: 'Use como quiser', isAvailable: true },
    ],
    labelAction: 'Comprar agora',
  },
  {
    subscription: '100 mil empresas',
    icon: <PlanStarterIcon />,
    price: `0,0023`,
    fullPrice: 'R$1887,00',
    discountedPrice: 'R$237,00',
    planType: 'pacote',
    planId: 'prod_QYfG4wtMIqfQBt',
    priceId: 'price_1PhXwRIg6gNaPiVCl9iDL4wk',
    lists: [
      { text: '100 mil leads', isAvailable: true },
      { text: 'Arquivo Excel', isAvailable: true },
      { text: 'Exportação de Leads', isAvailable: true },
      { text: 'Personalização do Funil de Vendas', isAvailable: true },
      { text: 'Acompanhamento de resultados', isAvailable: true },
      { text: 'Leads extraídos por setores', isAvailable: true },
      { text: 'Sem mensalidade', isAvailable: true },
      { text: 'Use como quiser', isAvailable: true },
    ],
    labelAction: 'Comprar agora',
  }
];
