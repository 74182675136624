import {createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}

const initialState = {
    isLoading: false,
    error: null,
    success: {
        create: false,
        edit: false,
        delete: false
    },
    boards: [],
    board: {
        id: null,
        cards: {},
        columns: {},
        columnOrder: [],
    },
};

const slice = createSlice({
    name: 'boards',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET BOARD
        getBoardsSuccess(state, action) {
            state.isLoading = false;
            const boards = action.payload;
            if (boards && boards.length > 0) {
                const board = boards[0];
                const sortedColumns = [...board.columns].sort((a, b) => a.position - b.position);
                const columns = objFromArray(sortedColumns);
                const columnOrder = sortedColumns.map(column => column.id);
                state.board = {
                    id: board.id,
                    columns,
                    columnOrder,
                };
            }
        },

        // CREATE NEW COLUMN
        createColumnSuccess(state, action) {
            const newColumn = action.payload;
            state.isLoading = false;
            state.success = {
                create: true
            };
            state.board.columns = {
                ...state.board.columns,
                [newColumn.id]: newColumn,
            };
            state.board.columnOrder.push(newColumn.id);
        },

        persistColumn(state, action) {
            state.board.columnOrder = action.payload;
        },

        // UPDATE COLUMN
        updateColumnSuccess(state, action) {
            const column = action.payload;

            state.isLoading = false;
            state.success = {
                edit: true
            }
            state.board.columns[column.id] = column;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            const {columnId} = action.payload;
            const deletedColumn = state.board.columns[columnId];

            state.isLoading = false;
            state.board.columns = omit(state.board.columns, [columnId]);
            state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
            state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
        },
    },
});

// Reducer
export default slice.reducer;

export const {actions} = slice;

// ----------------------------------------------------------------------

export function getBoard(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/boards/${id}`);
            dispatch(slice.actions.getLeadSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getBoards() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/boards');
            dispatch(slice.actions.getBoardsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/boards/columns/create', newColumn);
            dispatch(slice.actions.createColumnSuccess(response.data.column));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/api/boards/columns/${columnId}`, updateColumn);
            dispatch(slice.actions.updateColumnSuccess(updateColumn));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post('/api/boards/columns/delete', {columnId});
            dispatch(slice.actions.deleteColumnSuccess({columnId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
    return () => {
        dispatch(slice.actions.persistColumn(newColumnOrder));
    };
}
