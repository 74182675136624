import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: {
        create: false,
        edit: false,
        delete: false
    },
    companiesCount: 0,
    neighborhoodOptions: [],
    isLocationLoading: false,
    isNeighborhoodLoading: false,
    credits: null,
    leadsToConsume: 0,
    teams: [],
    selectedTeam: null,
    campaign: null,
    campaignList: [],
    campaignNameMissing: false,
    campaignName: "",
    campaignDescription: "",
    campaignStatus: "active",
    product: null,
    sortBy: null,
    leads: [],
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null,
    },
};

const slice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        startLocationLoading(state) {
            state.isLocationLoading = true;
        },

        // START LOADING
        startNeighborhoodLoading(state) {
            state.isNeighborhoodLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isNeighborhoodLoading = false;
            state.isLocationLoading = false;
            state.error = action.payload;
        },

        // GET CAMPAIGN
        getCampaignSuccess(state, action) {
            state.isLoading = false;
            state.campaign = action.payload;
        },

        // GET CAMPAIGNS
        getCampaignsSuccess(state, action) {
            state.isLoading = false;
            state.campaignList = action.payload;
        },

        // CREATE CAMPAIGN
        createCampaignSuccess(state, action) {
            state.isLoading = false;
            state.success = true;
            state.checkout.activeStep += 1;
            state.campaignName = "";
            state.campaignNameMissing = false;
            state.campaignDescription = "";
        },

        // UPDATE CAMPAIGN
        updateCampaignSuccess(state, action) {
            const updatedCampaign = action.payload;
            state.campaign.name = updatedCampaign.name;
            state.campaign.status = updatedCampaign.status;
            state.campaign.description = updatedCampaign.description;
            state.isLoading = false;
            state.success = {
                edit: true
            };
        },

        // REMOVE CAMPAIGN
        removeCampaignSuccess(state, action) {
            const removedCampaignId = action.payload;
            state.campaignList = state.campaignList.filter((campaign) => campaign.id !== removedCampaignId)
            state.isLoading = false;
            state.success = {
                delete: true
            };
        },

        // GET NEIGHBORHOODS
        getNeighborhoodsSuccess(state, action) {
            state.isNeighborhoodLoading = false;
            state.neighborhoodOptions = action.payload;
        },

        // GET MY CREDITS
        getMyCreditsSuccess(state, action) {
            state.isLoading = false;
            state.credits = action.payload;
        },

        // GET TEAMS
        getTeamsSuccess(state, action) {
            state.isLoading = false;
            state.teams = action.payload;
        },

        // GET COMPANIES COUNT
        getCompaniesCountSuccess(state, action) {
            state.isLoading = false;
            state.companiesCount = action.payload;
            state.checkout.activeStep += 1;
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.isLoading = false;
            state.products = action.payload;
        },

        // GET PRODUCT
        getProductSuccess(state, action) {
            state.isLoading = false;
            state.product = action.payload;
        },

        setLeadsToConsume(state, action) {
            state.leadsToConsume = action.payload;
        },

        setCampaignName(state, action) {
            const {payload} = action;
            if (payload && payload !== "") {
                state.campaignNameMissing = false;
            }
            state.campaignName = payload;
        },

        setCampaignNameMissing(state, action) {
            state.campaignNameMissing = action.payload;
        },

        setCampaignDescription(state, action) {
            state.campaignDescription = action.payload;
        },

        setCampaignStatus(state, action) {
            state.campaignStatus = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        sortByProducts(state, action) {
            state.sortBy = action.payload;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((cartItem) => cartItem.unit_price * cartItem.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const product = action.payload;
            state.checkout.cart = [product];
            state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
        },

        selectTeam(state, action) {
            state.selectedTeam = action.payload;
        },

        addTeamParticipant(state, action) {
            const participant = {
                ...action.payload,
                leadsTotal: 0,
                leadsInProgress: 0,
                leadsCompleted: 0
            };
            state.campaign.participants = [...state.campaign.participants, participant];
        },

        removeTeamParticipant(state, action) {
            const member = action.payload;
            state.campaign.participants = state.campaign.participants.filter((participant) => participant.id !== member.id);
        },

        changeLeadQtyToParticipant(state, action) {
            const {member, qty} = action.payload;
            let newQty = parseInt(qty, 10);
            const {campaign} = state;
            const participant = campaign.participants.find((participant) => participant.id === member.id);
            const campaignTotalLeads = campaign.totalLeads - campaign.leadsInProgress - campaign.completedLeads;
            if (newQty > campaignTotalLeads) {
                 newQty = campaignTotalLeads;
            }
            participant.leadsTotal = newQty + participant.leadsInProgress + participant.leadsCompleted;
        },

        addLeadQtyToParticipant(state, action) {
            const member = action.payload;
            const participant = state.campaign.participants.find((participant) => participant.id === member.id);
            participant.leadsTotal += 1;
        },

        removeLeadQtyToParticipant(state, action) {
            const member = action.payload;
            const participant = state.campaign.participants.find((participant) => participant.id === member.id);
            participant.leadsTotal -= 1;
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },

        increaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },

        clearLeadsDistribution(state, action) {
            state.leads = [];
        },

        distributeLeads(state, action) {
            const lead = action.payload;
            const isEmptyLeads = state.leads.length === 0;

            if (isEmptyLeads) {
                state.leads = [...state.leads, lead];
            } else {
                state.leads = state.leads.map((_lead) => {
                    const isExisted = _lead.memberId === lead.memberId;
                    if (isExisted) {
                        return {
                            ..._lead,
                            leadsQuantity: lead.leadsQuantity,
                        };
                    }
                    return _lead;
                });
            }
            state.leads = uniqBy([...state.leads, lead], 'memberId');
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    sortByProducts,
    filterProducts,
    distributeLeads,
    clearLeadsDistribution,
    selectTeam,
    addTeamParticipant,
    removeTeamParticipant,
    addLeadQtyToParticipant,
    changeLeadQtyToParticipant,
    removeLeadQtyToParticipant,
    setLeadsToConsume,
    setCampaignName,
    setCampaignDescription,
    setCampaignStatus,
    setCampaignNameMissing
} = slice.actions;

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}

// ----------------------------------------------------------------------

export function getCampaign(campaignId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/campaigns/${campaignId}`);
            dispatch(slice.actions.getCampaignSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCampaigns() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/campaigns');
            dispatch(slice.actions.getCampaignsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getTeams() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/teams', {
                params: {"includeMembers": true}
            });
            dispatch(slice.actions.getTeamsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAvailableCredits() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/account/my-credits');
            dispatch(slice.actions.getMyCreditsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createCampaign(request) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            // const response = await axios.post('/api/campaigns/create', request);
            const response = await axios.post('/functions/v1/create_campaign', request, {
                timeout: 600000,
                baseURL: 'https://kpvwvteiyrejlsvkujbv.supabase.co'
            });
            dispatch(slice.actions.createCampaignSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteCampaign(campaignId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/api/campaigns/${campaignId}`);
            dispatch(slice.actions.removeCampaignSuccess(campaignId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateCampaign(campaignId, campaignData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.patch(`/api/campaigns/${campaignId}`, campaignData);
            dispatch(slice.actions.updateCampaignSuccess(campaignData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
