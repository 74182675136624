// @mui
// hooks
import {Chip} from "@mui/material";
import useLocales from '../../../hooks/useLocales';
import useAuth from "../../../hooks/useAuth";
import Iconify from "../../../components/Iconify";
// components

// ----------------------------------------------------------------------

export default function CreditsIcon() {
    const {translate} = useLocales();
    const {credits, workspace} = useAuth();
    const availableCredits = credits && credits.credits || 0;

    if (workspace && !workspace.isAdmin) {
        return (<></>);
    }

    return (
        <>
            <Chip data-tut="credits" icon={<Iconify icon={'fa:dollar'}/>} label={translate('label.credits', {credits: availableCredits})} color="primary" variant="filled" sx={{pl:1}}/>
        </>
    );
}
