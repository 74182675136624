import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import {setSession} from "../utils/jwt";
import {PATH_AUTH, PATH_DASHBOARD} from "../routes/paths";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const {isAuthenticated, isInitialized} = useAuth();
    const {pathname, hash} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!isInitialized) {
        return <LoadingScreen/>;
    }

    if (!isAuthenticated) {
        if (hash.includes('access_token') && (hash.includes('type=invite') || hash.includes('type=recovery'))) {
            const hashParams = new URLSearchParams(hash.slice(1)); // Remove the leading '#' character
            const accessToken = hashParams.get('access_token');
            setSession(accessToken, "fake_refresh_token");
            return <Navigate to={PATH_AUTH.setPassword}/>;
        }
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login/>;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    return <>{children}</>;
}
