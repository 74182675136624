import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    success: {
        create: false,
        edit: false,
        delete: false
    },
    openDeals: 0,
    notStartedDeals: 0,
    closedDeals: 0,
    totalLeads: 0,
    totalAmountByStage: [],
    contacts: [],
    performers: [],
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET OPEN DEALS
        getOpenDealsSuccess(state, action) {
            state.isLoading = false;
            state.openDeals = action.payload;
        },

        // GET NOT STARTED DEALS
        getNotStartedDealsSuccess(state, action) {
            state.isLoading = false;
            state.notStartedDeals = action.payload;
        },

        // GET CLOSED DEALS
        getClosedDealsSuccess(state, action) {
            state.isLoading = false;
            state.closedDeals = action.payload;
        },

        // GET TOTAL LEADS
        getTotalLeadsSuccess(state, action) {
            state.isLoading = false;
            state.totalLeads = action.payload;
        },

        // GET TOTAL AMOUNT BY STAGE
        getTotalAmountByStageSuccess(state, action) {
            state.isLoading = false;
            state.totalAmountByStage = action.payload;
        },

        // GET CONTACTS
        getContactsSuccess(state, action) {
            state.isLoading = false;
            state.contacts = action.payload;
        },

        // GET TOP PERFORMERS
        getTopPerformersSuccess(state, action) {
            state.isLoading = false;
            state.performers = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

export const {actions} = slice;

// ----------------------------------------------------------------------

export function getOpenDeals(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/open-deals/${workspaceId}`);
            dispatch(slice.actions.getOpenDealsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getNotStartedDeals(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/not-started-deals/${workspaceId}`);
            dispatch(slice.actions.getNotStartedDealsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getClosedDeals(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/closed-deals/${workspaceId}`);
            dispatch(slice.actions.getClosedDealsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getTotalLeads(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/total-leads/${workspaceId}`);
            dispatch(slice.actions.getTotalLeadsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getTotalAmountByStage(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/amount-by-stage/${workspaceId}`);
            dispatch(slice.actions.getTotalAmountByStageSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function getContacts(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/contacts');
            dispatch(slice.actions.getContactsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function getTopPerformers(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/dashboard/top-performers/${workspaceId}`);
            dispatch(slice.actions.getTopPerformersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}
