import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node
};
export default function RoleBasedGuard({ children }) {
  const {workspace} = useAuth();

  if (workspace && !workspace.isAdmin) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Acesso restrito</AlertTitle>
          Você não tem permissão para acessar esta página
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
