import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import leadsReducer from './slices/leads';
import boardsReducer from './slices/boards';
import teamReducer from './slices/team';
import campaignReducer from './slices/campaign';
import openSearchReducer from './slices/openSearch';
import contactReducer from './slices/contact';
import dashboardReducer from './slices/dashboard';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
const campaignPersistConfig = {
  key: 'campaign',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
const openSearchPersistConfig = {
  key: 'openSearch',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  leads: leadsReducer,
  boards: boardsReducer,
  team: teamReducer,
  contact: contactReducer,
  campaign: campaignReducer,// persistReducer(campaignPersistConfig, campaignReducer),
  openSearch: persistReducer(openSearchPersistConfig, openSearchReducer),
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
