import numeral from 'numeral';
import "numeral/locales/pt-br";
import {forwardRef} from "react";
import {NumericFormat} from "react-number-format";

// ----------------------------------------------------------------------

export function fRealCurrency(number) {
    numeral.locale('pt-br');
    return numeral(number).format('$0,0.00');
}

export function fCurrency(number) {
    return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
    return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
    return numeral(number).format();
}

export function fShortenNumber(number) {
    return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
    return numeral(number).format('0.0 b');
}

export const NumericFormatCustom = forwardRef((
    props,
    ref,
) => {
    const {onChange, ...other} = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalSeparator=","
            thousandSeparator="."
            valueIsNumericString
            allowNegative={false}
        />
    );
});