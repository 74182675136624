// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const avatar = createAvatar(user?.name);
  return (
    <Avatar
      src={user?.photoUrl}
      alt={user?.name}
      color={user?.photoUrl ? 'default' : avatar.color}
      {...other}
    >
      {avatar.name}
    </Avatar>
  );
}
