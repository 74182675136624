import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    isLoadingForm: false,
    success: {
        create: false,
        edit: false,
        delete: false
    },
    error: null,
    contacts: [],
    contact: null,
    sortBy: null,
    isOpenModal: false,
    memberId: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};

const slice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING FORM
        startLoadingForm(state) {
            state.isLoadingForm = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isLoadingForm = false;
            state.error = action.payload;
        },

        // GET TEAMS
        getContactsSuccess(state, action) {
            state.isLoading = false;
            state.contacts = action.payload;
        },

        // GET CONTACT
        getContactSuccess(state, action) {
            state.isLoading = false;
            state.contact = action.payload;
        },

        // CREATE CONTACT
        createContactSuccess(state, action) {
            const newTeam = action.payload;
            state.isLoadingForm = false;
            state.success = {
                create: true
            };
            state.teams.push(newTeam);
        },

        // UPDATE CONTACT
        updateContactSuccess(state, action) {
            state.isLoadingForm = false;
            state.success = {
                edit: true
            };
        },

        // REMOVE TEAM
        removeTeamSuccess(state, action) {
            const removedTeamId = action.payload;
            state.teams = state.teams.filter((team) => team.id !== removedTeamId)
            state.isLoading = false;
            state.success = {
                delete: true
            };
        },

        // ADD TEAM MEMBER
        addNewMemberSuccess(state, action) {
            const newMember = action.payload;
            newMember.status = newMember.status === "true" ? "active" : "inactive";
            state.team.members.push(newMember);
            state.isLoading = false;
        },

        // UPDATE TEAM MEMBER
        updateMemberSuccess(state, action) {
            const member = action.payload;
            const teamMember = state.team.members.filter((user) => user.id === member.userId);
            teamMember.status = member.status;
            teamMember.role = member.role;
            state.isLoading = false;
        },

        // REMOVE TEAM MEMBER
        removeMemberSuccess(state, action) {
            const member = action.payload;
            state.team.members = state.team.members.filter((user) => user.id !== member.userId);
            state.isLoading = false;
        },

        //  SORT & FILTER PRODUCTS
        sortByProducts(state, action) {
            state.sortBy = action.payload;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const product = action.payload;
            const isEmptyCart = state.checkout.cart.length === 0;

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, product];
            } else {
                state.checkout.cart = state.checkout.cart.map((_product) => {
                    const isExisted = _product.id === product.id;
                    if (isExisted) {
                        return {
                            ..._product,
                            quantity: _product.quantity + 1,
                        };
                    }
                    return _product;
                });
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },

        increaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const productId = action.payload;
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1,
                    };
                }
                return product;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },

        // OPEN MODAL
        openModal(state, action) {
            const memberId = action.payload;
            state.isOpenModal = true;
            if (memberId)
                state.memberId = memberId;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedRange = null;
            state.memberId = null;
        },
    },
});

// Reducer
export default slice.reducer;

export const {openModal, closeModal, selectEvent} = slice.actions;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    sortByProducts,
    filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getContact(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/contacts/${id}`);
            dispatch(slice.actions.getContactSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getContacts() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/contacts');
            dispatch(slice.actions.getContactsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}

// ----------------------------------------------------------------------

export function getTeam(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/teams/${id}`);
            dispatch(slice.actions.getTeamSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createContact(contactData) {
    return async () => {
        dispatch(slice.actions.startLoadingForm());
        try {
            const response = await axios.post('/api/contacts/create', contactData);
            dispatch(slice.actions.createContactSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateContact(contactId, contactData) {
    return async () => {
        dispatch(slice.actions.startLoadingForm());
        try {
            await axios.patch(`/api/contacts/${contactId}`, contactData);
            dispatch(slice.actions.updateContactSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteTeam(teamId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/api/teams/${teamId}`);
            dispatch(slice.actions.removeTeamSuccess(teamId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addNewMember(newMember) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post('/api/teams/add-member', {
                teamId: newMember.teamId,
                name: newMember.name,
                email: newMember.email,
                role: newMember.role,
                status: newMember.status,
            });
            dispatch(slice.actions.addNewMemberSuccess(newMember));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateMember(member) {
    return async () => {
        console.log(member)
        dispatch(slice.actions.startLoading());
        try {
            await axios.patch('/api/teams/edit-member', {
                teamId: member.teamId,
                userId: member.userId,
                role: member.role.toLowerCase(),
                status: member.status === true || member.status === "true" ? "active" : "deactivated",
            });
            dispatch(slice.actions.updateMemberSuccess(member));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeMember(member) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post('/api/teams/remove-member', {
                teamId: member.teamId,
                userId: member.userId
            });
            dispatch(slice.actions.removeMemberSuccess(member));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
