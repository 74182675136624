// @mui
import {styled} from '@mui/material/styles';
import {CardActionArea} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
//
import Iconify from '../Iconify';
import {IconButtonAnimate} from "../animate";

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({theme}) => ({
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.disabled,
    border: `solid 1px ${theme.palette.grey[500_12]}`,
    borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingMode() {
    const {themeMode, onToggleMode} = useSettings();

    return (
        <IconButtonAnimate
            data-tut="mode"
            onClick={onToggleMode}
            sx={{
                width: 40,
                height: 40,
            }}
        >
            <Iconify icon={themeMode === 'dark' ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28} height={28}/>
        </IconButtonAnimate>
    );
}
