import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  credits: null,
  workspace: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, credits, workspace } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      credits,
      workspace
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, credits, workspace } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      credits,
      workspace,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (accessToken && refreshToken && isValidToken(accessToken)) {
          setSession(accessToken, refreshToken);

          const response = await axios.get('/api/account/my-account');
          const {credits, workspace, ...rest} = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: rest,
              credits,
              workspace
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              credits: null,
              workspace: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            credits: null,
            workspace: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('/api/auth/login', {
      username,
      password,
    });
    const { accessToken, refreshToken, user, profile, credits, workspace } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: profile,
        credits,
        workspace
      },
    });
  };

  const register = async (username, password, name) => {
    const response = await axios.post('/api/account/signup', {
      username,
      password,
      name
    });
    const { accessToken, refreshToken, user } = response.data;

    setSession(accessToken, refreshToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
