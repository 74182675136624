import {createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}

const initialState = {
    isLoadingForm: false,
    isMultiLoading: {},
    isLoading: false,
    postCommentSuccess: false,
    error: null,
    success: {
        create: false,
        edit: false,
        delete: false
    },
    leads: [],
    lead: null,
    filters: {
        assignees: [],
        priority: [],
        campaign: []
    },
    isOpenModal: false,
    board: {
        cards: {},
        columns: {},
        columnOrder: [],
    },
};

const slice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        // CLEAR SUCCESS AND ERROR STATE
        clearAPIState(state) {
            state.isLoading = false;
            state.success = null;
            state.error = null;
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START MULTI LOADING
        startMultitLoading(state, action) {
            const {id} = action.payload;
            state.isMultiLoading[id] = true;
        },

        // START LOADING FORM
        startLoadingForm(state) {
            state.isLoadingForm = true;
        },

        // HAS ERROR
        hasError(state, action) {
            const {id} = action.payload;
            if (id) {
                state.error = action.payload.error;
                state.isMultiLoading[id] = false;
            } else {
                state.error = action.payload;
            }
            state.isLoading = false;
            state.isLoadingForm = false;
            state.postCommentSuccess = false;
        },

        // GET LEAD
        getLeadSuccess(state, action) {
            state.isLoading = false;
            state.lead = action.payload;
        },

        // UPDATE CAMPAIGN LEAD
        updateCampaignLeadSuccess(state, action) {
            const {leadData, isFromCard} = action.payload;
            if (!isFromCard) {
                state.lead.campaign.title = leadData.title;
                state.lead.campaign.value = leadData.value;
            }

            state.isLoadingForm = false;
            state.success = {
                edit: true
            };
        },

        // UPDATE COMPANY LEAD
        updateCompanySuccess(state, action) {
            const updatedLead = action.payload;
            state.lead.companyName = updatedLead.name;
            state.lead.category = updatedLead.category;
            state.lead.legalNature = updatedLead.legal_nature;
            state.lead.capitalSocial = updatedLead.capital_social;
            state.lead.email = updatedLead.email;
            state.lead.phone = updatedLead.phone;
            state.lead.address.address = updatedLead.address;
            state.lead.address.address2 = updatedLead.address_2;
            state.lead.address.number = updatedLead.address_number;
            state.lead.address.neighborhood = updatedLead.address_neighborhood;
            state.lead.address.city = updatedLead.address_city;
            state.lead.address.state = updatedLead.address_state;
            state.lead.address.zipcode = updatedLead.zipcode;

            state.isLoadingForm = false;
            state.success = {
                edit: true
            };
        },

        // GET LEADS
        getLeadsSuccess(state, action) {
            state.isLoading = false;
            state.leads = action.payload;
        },

        // GET BOARD
        getBoardSuccess(state, action) {
            state.isLoading = false;
            const boards = action.payload;
            if (boards && boards.length > 0) {
                const board = boards[0];
                const cards = objFromArray(board.cards);
                const sortedColumns = [...board.columns].sort((a, b) => a.position - b.position);
                const columnsWithCards = sortedColumns.map((column) => {
                    return {
                        ...column,
                        cardIds: board.cards
                            .filter((card) => card.columnId === column.id)
                            .map((card) => card.id),
                    };
                });
                const columns = objFromArray(columnsWithCards);
                const columnOrder = sortedColumns.map(column => column.id);
                state.board = {
                    cards,
                    columns,
                    columnOrder,
                };
            }
        },

        // CREATE NEW COLUMN
        createColumnSuccess(state, action) {
            const newColumn = action.payload;
            state.isLoading = false;
            state.board.columns = {
                ...state.board.columns,
                [newColumn.id]: newColumn,
            };
            state.board.columnOrder.push(newColumn.id);
        },

        // POST NEW COMMENT
        postCommentSuccess(state, action) {
            state.isLoading = false;
            state.postCommentSuccess = true;
            const {data, user} = action.payload;
            const {cardId} = data;
            const comment = {
                ...data,
                name: user.name,
                photoUrl: user.photoUrl,
                email: user.email
            }
            state.board.cards[cardId].comments.unshift(comment);
        },

        // POST NEW ATTACHMENT
        postAttachmentSuccess(state, action) {
            state.isLoading = false;
            const attachment = action.payload;
            state.board.cards[attachment.cardId].attachments.push(attachment);
        },

        // REMOVE ATTACHMENT
        removeAttachmentSuccess(state, action) {
            state.isLoading = false;
            const {cardId, id} = action.payload;
            state.board.cards[cardId].attachments = state.board.cards[cardId].attachments.filter((attachment) => attachment.id !== id);
        },

        // ADD/REMOVE ASSIGNEE TO THE LEAD
        addAssigneeSuccess(state, action) {
            const {isRemove, cardId, member} = action.payload;
            state.isMultiLoading[member.id] = false;
            if (!isRemove)
                state.board.cards[cardId].assignees.push(member);
            else
                state.board.cards[cardId].assignees = state.board.cards[cardId].assignees.filter((assignee) => assignee.id !== member.id);
        },

        // ADD/REMOVE ASSIGNEE TO THE LEAD
        moveLeadToBoardSuccess(state, action) {
            const leadId = action.payload;
            state.isMultiLoading[leadId] = false;
            state.leads = state.leads.filter((lead) => lead.id !== leadId);
        },

        persistCard(state, action) {
            const columns = action.payload;
            state.board.columns = columns;
        },

        moveCardToColumnSuccess(state, action) {
            state.isLoading = false;
            const {columnId, cardId, originalColumnId} = action.payload;

            const startColumn = state.board.columns[originalColumnId];
            const finalColumn = state.board.columns[columnId];

            const startCardIds = [...startColumn.cardIds].filter((card) => card !== cardId);
            const updatedStartColumn = {
                ...startColumn,
                cardIds: startCardIds,
            };

            const finishCardIds = [...finalColumn.cardIds];
            if (finishCardIds.indexOf(cardId) === -1) {
                finishCardIds.push(cardId);
            }

            const updatedFinishColumn = {
                ...finalColumn,
                cardIds: finishCardIds,
            };
            state.board.columns = {
                ...state.board.columns,
                [updatedStartColumn.id]: updatedStartColumn,
                [updatedFinishColumn.id]: updatedFinishColumn,
            };
            state.board.cards[cardId].columnId = columnId;
        },

        getCardSuccess(state, action) {
            state.isLoading = false;
            const {id, ...payload} = action.payload;
            state.board.cards[id] = {...state.board.cards[id], ...payload};
        },

        updateCardSuccess(state, action) {
            state.isLoading = false;
            const {request, cardId} = action.payload;
            state.success = {
                edit: true
            };
            state.board.cards[cardId] = {...state.board.cards[cardId], ...request};
        },

        persistColumn(state, action) {
            state.board.columnOrder = action.payload;
        },

        addTask(state, action) {
            const {card, columnId} = action.payload;

            state.board.cards[card.id] = card;
            state.board.columns[columnId].cardIds.push(card.id);
        },

        deleteTask(state, action) {
            const {cardId, columnId} = action.payload;

            state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
            state.board.cards = omit(state.board.cards, [cardId]);
        },

        // UPDATE COLUMN
        updateColumnSuccess(state, action) {
            state.isLoading = false;
            const column = action.payload;
            state.board.columns[column.id] = column;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            const {columnId} = action.payload;
            const deletedColumn = state.board.columns[columnId];

            state.isLoading = false;
            state.board.columns = omit(state.board.columns, [columnId]);
            state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
            state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
        },

        // FILTER CARDS
        filterCards(state, action) {
            const {assignee, priority, campaign} = action.payload;
            if (assignee) state.filters.assignees = assignee;
            if (priority) state.filters.priority = priority;
            if (campaign) state.filters.campaign = campaign;
        },

    },
});

// Reducer
export default slice.reducer;

export const {actions} = slice;
export const {
    postAttachmentSuccess,
    removeAttachmentSuccess,
    filterCards
} = slice.actions;

// ----------------------------------------------------------------------

export function getLead(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/leads/${id}`);
            dispatch(slice.actions.getLeadSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLeads() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/leads');
            dispatch(slice.actions.getLeadsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateCompany(companyId, companyData, isFromCard = false) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/api/leads/company/${companyId}`, companyData);
            if (!isFromCard) {
                dispatch(slice.actions.updateCompanySuccess(companyData));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateCampaignLead(campaignId, leadId, leadData, isFromCard = false) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/api/leads/campaign/${campaignId}/${leadId}`, leadData);
            dispatch(slice.actions.updateCampaignLeadSuccess({leadData, isFromCard}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getBoard(workspaceId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/leads/boards/${workspaceId}`);
            dispatch(slice.actions.getBoardSuccess(response.data));
            // dispatch(slice.actions.getBoardSuccess(_leadsBoard.board));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearAPIState() {
    return () => {
        dispatch(slice.actions.clearAPIState());
    };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/kanban/columns/new', newColumn);
            dispatch(slice.actions.createColumnSuccess(response.data.column));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/kanban/columns/update', {
                columnId,
                updateColumn,
            });
            dispatch(slice.actions.updateColumnSuccess(response.data.column));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post('/api/kanban/columns/delete', {columnId});
            dispatch(slice.actions.deleteColumnSuccess({columnId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
    return () => {
        dispatch(slice.actions.persistColumn(newColumnOrder));
    };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
    return () => {
        dispatch(slice.actions.persistCard(columns));
    };
}

// ----------------------------------------------------------------------

export function getCard(workspace, card) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/leads/card/${workspace.id}/${card.id}`);
            dispatch(slice.actions.getCardSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateCard(request, card) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/api/leads/card/${card.id}`, request);
            if (request.columnId) {
                if (request.columnId !== card.columnId)
                    dispatch(slice.actions.moveCardToColumnSuccess({
                        columnId: request.columnId,
                        cardId: card.id,
                        originalColumnId: card.columnId
                    }));
            } else {
                dispatch(slice.actions.updateCardSuccess({request, cardId: card.id}));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addTask({card, columnId}) {
    return () => {
        dispatch(slice.actions.addTask({card, columnId}));
    };
}

// ----------------------------------------------------------------------

export function deleteTask({cardId, columnId}) {
    return (dispatch) => {
        dispatch(slice.actions.deleteTask({cardId, columnId}));
    };
}

// ----------------------------------------------------------------------

export function postComment(request, user) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/api/leads/comment', request);
            dispatch(slice.actions.postCommentSuccess({data: response.data, user}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addRemoveAssignee(request, member) {
    return async () => {
        dispatch(slice.actions.startMultitLoading(member));
        try {
            const response = await axios.post('/api/leads/assignees', request);
            dispatch(slice.actions.addAssigneeSuccess({isRemove: request.isRemove, cardId: request.card_id, member}));
        } catch (error) {
            dispatch(slice.actions.hasError({error, id: member.id}));
        }
    };
}

// ----------------------------------------------------------------------

export function moveLeadToBoard(request, lead) {
    return async () => {
        dispatch(slice.actions.startMultitLoading(lead));
        try {
            const response = await axios.post('/api/leads/move-to-board', request);
            dispatch(slice.actions.moveLeadToBoardSuccess(lead.id));
        } catch (error) {
            dispatch(slice.actions.hasError({error, id: lead.id}));
        }
    };
}
