import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_DASHBOARD} from '../routes/paths';
import {useSelector} from "../redux/store";
import axios from "../utils/axios";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default function GuestGuard({children}) {
    const {isAuthenticated} = useAuth();
    const {checkout} = useSelector((state) => state.product);

    if (isAuthenticated) {
        const {cart} = checkout;
        if (checkout && cart.length > 0) {
            const priceId = cart[0].id;
            axios.post('/api/checkout', {
                priceId
            }).then((response) => {
                if (response.status === 200) {
                    window.location.href = response.data;
                }
            });
        } else {
            return <Navigate to={PATH_DASHBOARD.root}/>;
        }
    }

    return <>{children}</>;
}
